
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "department-update-modal-component",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    departmentName: String,
    publicId: String,
  },
  data() {
    return { name: this.departmentName };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);

    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .required()
        .label("Department name"),
    });

    const submitForm = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.patch(
              `${variables.SUPERADMIN_DEPARTMENT_ROUTE}/${props.publicId}`,
              values,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data, "success");
                resetForm();
                window.location.reload();

                // const button = document.getElementById(
                //   "update_department_cancel"
                // ) as HTMLElement;

                // console.log(button);

                // button.addEventListener("click", function() {
                //   console.log("Button clicked!");
                // });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    return {
      validationSchema,
      submitButton1,
      submitForm,
    };
  },
  watch: {
    // Watch for changes in the prop and update the local copy
    departmentName(newValue) {
      this.name = newValue;
    },
  },
});
