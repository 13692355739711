
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "add-department-modal-component",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return { name: "" };
  },
  setup() {
    const submitButton1 = ref<null | HTMLButtonElement>(null);

    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .required()
        .label("Department name"),
    });

    const submitForm = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(
              `${variables.SUPERADMIN_DEPARTMENTS_ROUTE}`,
              values,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                window.location.reload();
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    return {
      validationSchema,
      submitButton1,
      submitForm,
    };
  },
});
