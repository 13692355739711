
import { defineComponent } from "vue";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import AddDepartment from "@/components/modals/AddDepartment.vue";
import UpdateDepartment from "@/components/modals/UpdateDepartment.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";

export default defineComponent({
  name: "admin-departments-table",
  components: { UpdateDepartment, AddDepartment },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      departments: [],
      name: "",
      publicId: "",
    };
  },
  created() {
    this.getDepartments();
    this.displayDatatable();
  },
  methods: {
    getDepartments() {
      //Fetch list of all departments
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_DEPARTMENTS_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.departments = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    deleteDepartment(publicId) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.delete(
          `${variables.SUPERADMIN_DEPARTMENT_ROUTE}/${publicId}`
        )
          .then(({ data }) => {
            variables.toastAlert(data.data, "success");
            this.getDepartments();
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    departmentProps(publicId, name) {
      this.publicId = publicId;
      this.name = name;
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
          destroy: true,
        });
      }, 3000);
    },
  },
});
